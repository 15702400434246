import '../css/app.scss';

import 'jquery';
import $ from 'jquery';

import './jquery.countdown.min.js';

// import 'bootstrap';
import './libs/bootstrap.min';
// import './../../node_modules/bootstrap/dist/js/bootstrap';
import './libs/jquery.appear.js';
import './libs/owl.carousel.min';
import './libs/scrolla.jquery.min';
import './libs/jquery.wavify';
import './libs/gauge.min';
import './libs/ToProgress.min.js';
import './libs/jquery.fancybox';

// import 'owl.carousel';
// import '../../node_modules/jquery-scrolla/dist/scrolla.jquery';
// import 'wavify';

// import './gauge.min.js';
import './TweenMax.min.js';
import './ToProgress.min.js';
import './libs/custom';

// import './bootstrap';
// import './../../bower_components/jquery-ui/jquery-ui';
// import './jquery-ui-slider-pips';
// import 'jquery-ui-touch-punch';
// import '../../node_modules/jquery-fancybox/source/js/jquery.fancybox';


// import './custom';


// import 'bootstrap-datepicker';
// import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.standalone.css'

import Vue from 'vue';
import './infrastructure/Flash';
import './infrastructure/Flashes';
import './infrastructure/Tab';
import './infrastructure/Tabs';
import './infrastructure/Modal';
import './infrastructure/Carousel';
import './infrastructure/DeleteLink';
import './infrastructure/DeleteModuleItem';
import './infrastructure/PostLink';
import './infrastructure/InlineValueEdit';
import './infrastructure/FileUploadButton';
import './infrastructure/FilesList';
import './infrastructure/Slider';
import './components/EntityTranslation';
import './components/EntityTranslationsForm';
import './components/CreditCalculator';
import './components/CreditOffer';
import './components/CreditComparision';
import './components/PollAnswer';
import './components/PollQuestion';
import './components/Poll';
import './components/Gmaps';
import './components/SliderModule';
import './components/PollParticipation';
import './components/ImageGallery';
import './components/CookieDeclaration';
import './components/ZopimChat';
import './components/LoginModal';
import './components/UserInviteHistory';
import './components/UserActivity';
import './components/UserMultiselect';

import 'vue-range-slider';

import {Application} from "stimulus"
import {definitionsFromContext} from "stimulus/webpack-helpers"

window.EventManager = new class {
    constructor() {
        this.vue = new Vue();
    }

    fire(event, data = null) {
        this.vue.$emit(event, data);
    }

    listen(event, callback) {
        this.vue.$on(event, callback);
    }
};

window.flash = function (message, level = 'success') {
    window.EventManager.fire('flash', {message, level});
};

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

Vue.options.delimiters = ['[[', ']]'];
Vue.config.ignoredElements = ['x-trans'];

const app = new Vue({
    el: '#app',
    data: {
        dynamicComponents: []
    },
    mounted() {
        EventManager.listen('add-component', this.addComponent);
        EventManager.listen('remove-component', this.removeComponent);
    },
    methods: {
        addComponent(data) {
            this.dynamicComponents.push(data);
        },

        removeComponent(componentId) {
            let index = this.dynamicComponents.findIndex(item => item.id === componentId);

            this.dynamicComponents.splice(index, 1);
        }
    }
});

$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});

let elements = document.querySelectorAll('input, select, textarea');

elements.forEach(element => {
    element.addEventListener('invalid', function () {
        element.scrollIntoView(false);
    });
});

$(function() {
    $("form").submit(function(event) {
        $(this).find('button[type="submit"]').not(".prevent-auto-disabling").prop("disabled", true);
    });
});


$(document).ready(function () {
    $('.intro-slider').owlCarousel({
        nav:false,
        items:1,
        loop:true,
        margin:0,
        autoplay:true,
        autoplayTimeout:12000,
        smartSpeed: 600,
        autoplayHoverPause:true
    });
    
    $('.comments').owlCarousel({
        nav:false,
        navigationText : ["",""],
        rewindNav : true,

        loop: true,
        margin: 30,
        items: 3,

        slideSpeed: 2000,
        paginationSpeed: 800,
        rewindSpeed: 1000,

        autoPlay: 15000,
        stopOnHover: true,

        responsiveClass: true,
        responsive: {
            0: {
                items: 1,
                nav: true
            },
            600: {
                items: 3,
                nav: false
            },
            1000: {
                items: 3,
                nav: true
            }
        }
    });

    $('.owl-prev, .owl-next').hide();

});

$(document).ready(function () {
   $('.dictionary-key').click(function () {
       $('.dictionary-key').removeClass("active");
       $(this).prop('aria-selected', true);
       $(this).addClass('active');
   });
});


//shortcut for $(document).ready
// $(function(){
//     console.log("togling")
//     if(window.location.hash) {
//         var hash = window.location.hash;
//         $(hash).modal('toggle');
//     }
// });

// {literal}
// {/literal}



/**
 *  RECOMMENDED CONFIGURATION VARIABLES: EDIT AND UNCOMMENT THE SECTION BELOW TO INSERT DYNAMIC VALUES FROM YOUR PLATFORM OR CMS.
 *  LEARN WHY DEFINING THESE VARIABLES IS IMPORTANT: https://disqus.com/admin/universalcode/#configuration-variables*/
/*
 var disqus_config = function () {
 this.page.url = PAGE_URL;  // Replace PAGE_URL with your page's canonical URL variable
 this.page.identifier = PAGE_IDENTIFIER; // Replace PAGE_IDENTIFIER with your page's unique identifier variable
 };
 */
// (function () { // DON'T EDIT BELOW THIS LINE
//     var d = document, s = d.createElement('script');
//     s.src = 'https://axi-bg.disqus.com/embed.js';
//     s.setAttribute('data-timestamp', +new Date());
//     (d.head || d.body).appendChild(s);
// })();

$(function () {

   $('.js-modal-close').click(function () {
        $(this).closest('.modal').hide()
   });
});

$(function() {
    $("ul.js-questions").each(function() {
        $("li:gt(4)", this).hide(); /* :gt() is zero-indexed */
    });
});

$(document).ready(function(){
    $('.disabled-button').removeClass("disabled-button");

    if (jQuery('.search-form-submit').length > 0) {
        document.querySelector('.search-form-submit').onclick = (e) => {
            if (document.querySelector("input[name=\'queryString\']").value.length < 3) {
                alert('Minimum 3 characters needed to search!');
                e.preventDefault()
            }
        };
    }
});

document.addEventListener("DOMContentLoaded", function(event) {
    const gdprCheckbox = document.querySelector('.js-modal-check');

    if (gdprCheckbox !== null) {
        gdprCheckbox.addEventListener('click', (event) => {
            if (!event.target.checked) {
                event.stopPropagation();
            }
        });
    }
});

document.addEventListener("DOMContentLoaded", function(event) {
    const uploadButton = document.querySelector('.js-upload-button');

    if (uploadButton !== null) {
        uploadButton.addEventListener('click', (event) => {
            event.preventDefault();
            document.querySelector('#career_application_cv').click();
        });
    }

});

document.addEventListener("DOMContentLoaded", function(event) {
    const contactPrivacyLabel = document.querySelector('.career-privacy-label');

    if (contactPrivacyLabel !== null) {
        contactPrivacyLabel.innerHTML = 'Запознат съм с <a href="/gdpr" target="_blank">Политиката</a> за обработка на лични данни и я приемам'
    }
});

// To make sure Google Maps API is loaded and have access to google obj
window.dispatchMapsEvent = function (...args) {
    const event = document.createEvent("Events")
    event.initEvent("google-maps-callback", true, true)
    event.args = args
    window.dispatchEvent(event)
}
